/** External Dependencies */
import React, { useCallback, useMemo, useRef, useState } from 'react';

/** Internal Dependencies */
import { SELECT_TAB, SET_ORIGINAL_IMAGE } from '../../actions';
import { useStore } from '../../hooks';
import UploadIcon from '@scaleflex/icons/plus';
import HiddenUploadInput from '../../components/common/HiddenUploadInput';
import { StyledUploadBar, StyledImgItem } from './UploadBar.styled';
import { RESET } from '../../actions';


const UploadBar = () => {
  const {
    t,
    dispatch,
    config
  } = useStore();
  const [isLoading, setIsLoading] = useState();
  const uploadImgsInput = useRef();

  const currentTabId = '';

  const selectTab = useCallback((newTabId) => {
    dispatch({
      type: SELECT_TAB,
      payload: {
        tabId: newTabId,
      },
    });
  }, []);

  const triggerUploadInput = () => {
    if (uploadImgsInput.current) {
      uploadImgsInput.current.click();
    }
  };
  const importImages = (e) => {
    if (e.target.files) {
      setIsLoading(true);
      const imgFile = e.target.files[0];
      if (imgFile.type.startsWith('image/')) {
        const img = new Image();
        img.src = URL.createObjectURL(imgFile);
        img.name = imgFile.name;        
        img.onload = () => {
          URL.revokeObjectURL(imgFile);
          setIsLoading(false);
          dispatch({
            type: SET_ORIGINAL_IMAGE,
            payload: {
              originalImage: img,
            },
          });    
          dispatch({ type: RESET, payload: { config } });        
        };
        img.onerror = () => {
          setIsLoading(false);
        };
      }
      setIsLoading(false);
    }
    e.target.value = '';
  }
  // If only 1 tab is needed then no need to have the tabs sidebar.
  if ([].length === 1) {
    return null;
  }

  return (
    <StyledUploadBar className="FIE_uplaod-bar">
      {[{id:'1'}].map(({ id, labelKey, icon }) => (
        <StyledImgItem
          key={id}
          id={id}
          isSelected={currentTabId === id}
          onClick={triggerUploadInput}
        >
          <UploadIcon size={22} />
          <HiddenUploadInput
            ref={uploadImgsInput}
            onChange={isLoading ? undefined : importImages}
            disabled={isLoading}
          />          
        </StyledImgItem>
      ))}
    </StyledUploadBar>
  );
};

export default UploadBar;
