import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from './react-filerobot-image-editor';
import { saveAs } from 'file-saver';
import './App.css';
import originImg from './assets/images/originImg.jpeg'


function App() {
  return (
    <div className="App">
      <FilerobotImageEditor
        source={originImg}
        onSave={(editedImageObject, designState) => {
          console.log('saved', editedImageObject, designState)
            saveAs(editedImageObject.imageBase64, editedImageObject.fullName);
          }
        }
        onClose={()=>{}}
        annotationsCommon={{
          fill: '#ff0000',
        }}
        Text={{ text: 'PhotoFun' }}
        Rotate={{ angle: 90, componentType: 'slider' }}
        Crop={{
          presetsItems: [
            {
              titleKey: 'classicTv',
              descriptionKey: '4:3',
              ratio: 4 / 3,
              // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
            },
            {
              titleKey: 'cinemascope',
              descriptionKey: '21:9',
              ratio: 21 / 9,
              // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
            },
          ],
          presetsFolders: [
            {
              titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
              // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
              groups: [
                {
                    "titleKey": "linkedIn",
                    "items": [
                        {
                            "titleKey": "profilePhoto",
                            "width": 400,
                            "height": 400,
                            "descriptionKey": "liProfilePhotoSize",
                            "disableManualResize": false
                        },
                        {
                            "titleKey": "profileCoverPhoto",
                            "width": 1584,
                            "height": 396,
                            "descriptionKey": "liProfileCoverPhotoSize"
                        },
                        {
                            "titleKey": "blogPostPhoto",
                            "width": 1200,
                            "height": 627,
                            "descriptionKey": "liBlogPostPhotoSize"
                        },
                        {
                            "titleKey": "companyLogo",
                            "width": 300,
                            "height": 300,
                            "descriptionKey": "liCompanyLogoSize"
                        },
                        {
                            "titleKey": "companyPageCover",
                            "width": 1128,
                            "height": 191,
                            "descriptionKey": "liCompanyPageCoverSize"
                        }
                    ]
                },
                {
                    "titleKey": "twitter",
                    "items": [
                        {
                            "titleKey": "profilePhoto",
                            "width": 400,
                            "height": 400,
                            "descriptionKey": "twProfilePhotoSize"
                        },
                        {
                            "titleKey": "headerPhoto",
                            "width": 1500,
                            "height": 500,
                            "descriptionKey": "twHeaderPhotoSize"
                        },
                        {
                            "titleKey": "inStreamPhoto",
                            "width": 1600,
                            "height": 1900,
                            "descriptionKey": "twInStreamPhotoSize"
                        }
                    ]
                },
                {
                    "titleKey": "instagram",
                    "items": [
                        {
                            "titleKey": "profilePhoto",
                            "width": 320,
                            "height": 320,
                            "descriptionKey": "igProfilePhotoSize"
                        },
                        {
                            "titleKey": "feedPortraitPhoto",
                            "width": 1080,
                            "height": 1350,
                            "descriptionKey": "igFeedPortraitPhotoSize"
                        },
                        {
                            "titleKey": "feedLandscapePhoto",
                            "width": 1080,
                            "height": 566,
                            "descriptionKey": "igFeedLandscapePhotoSize"
                        },
                        {
                            "titleKey": "feedSquarePhoto",
                            "width": 1080,
                            "height": 1080,
                            "descriptionKey": "igFeedSquarePhotoSize"
                        },
                        {
                            "titleKey": "storyPhoto",
                            "width": 1080,
                            "height": 1920,
                            "descriptionKey": "igStoryPhotoSize"
                        }
                    ]
                },
                {
                    "titleKey": "facebook",
                    "items": [
                        {
                            "titleKey": "profilePhoto",
                            "width": 170,
                            "height": 170,
                            "descriptionKey": "fbProfilePhotoSize"
                        },
                        {
                            "titleKey": "profileCoverPhoto",
                            "width": 851,
                            "height": 315,
                            "descriptionKey": "fbProfileCoverPhotoSize"
                        },
                        {
                            "titleKey": "eventCoverPhoto",
                            "width": 1200,
                            "height": 628,
                            "descriptionKey": "fbEventCoverPhotoSize"
                        },
                        {
                            "titleKey": "timelinePhoto",
                            "width": 1200,
                            "height": 630,
                            "descriptionKey": "fbTimelinePhotoSize"
                        },
                        {
                            "titleKey": "storyPhoto",
                            "width": 1080,
                            "height": 1920,
                            "descriptionKey": "fbStoryPhotoSize"
                        }
                    ]
                }
            ],
            },
          ],
        }}
        tabsIds={[TABS.ADJUST, TABS.FILTERS, TABS.ANNOTATE, TABS.FINETUNE, TABS.WATERMARK, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
        defaultTabId={TABS.CROP} // or 'Annotate'
        defaultToolId={TOOLS.TEXT} // or 'Text'
        useBackendTranslations={false}
        previewPixelRatio={4}
        savingPixelRatio={6}
      />
    </div>
  );
}

export default App;
