const En = {
  name: 'Name',
  save: 'Save',
  saveAs: 'Save as',
  back: 'Back',
  loading: 'Loading...',
  resetOperations: 'Reset/delete all operations',
  changesLoseConfirmation: 'All changes will be lost',
  changesLoseConfirmationHint: 'Are you sure you want to continue?',
  cancel: 'Cancel',
  continue: 'Continue',
  undoTitle: 'Undo last operation',
  redoTitle: 'Redo last operation',
  showImageTitle: 'Show original image',
  zoomInTitle: 'Zoom in',
  zoomOutTitle: 'Zoom out',
  toggleZoomMenuTitle: 'Toggle zoom menu',
  adjustTab: 'Adjust',
  finetuneTab: 'Finetune',
  filtersTab: 'Filters',
  watermarkTab: 'Watermark',
  annotateTab: 'Draw',
  resize: 'Resize',
  resizeTab: 'Resize',
  invalidImageError: 'Invalid image provided.',
  uploadImageError: 'Error while uploading the image.',
  areNotImages: 'are not images',
  isNotImage: 'is not image',
  toBeUploaded: 'to be uploaded',
  cropTool: 'Crop',
  original: 'Original',
  custom: 'Custom',
  square: 'Square',
  landscape: 'Landscape',
  portrait: 'Portrait',
  ellipse: 'Ellipse',
  classicTv: 'Classic TV',
  cinemascope: 'Cinemascope',
  arrowTool: 'Arrow',
  blurTool: 'Blur',
  brightnessTool: 'Brightness',
  contrastTool: 'Contrast',
  ellipseTool: 'Ellipse',
  unFlipX: 'Un-Flip X',
  flipX: 'Flip X',
  unFlipY: 'Un-Flip Y',
  flipY: 'Flip Y',
  hsvTool: 'HSV',
  hue: 'Hue',
  saturation: 'Saturation',
  value: 'Value',
  imageTool: 'Image',
  importing: 'Importing...',
  addImage: '+ Add image',
  uploadImage: 'Upload image',
  fromGallery: 'From gallery',
  lineTool: 'Line',
  penTool: 'Pen',
  polygonTool: 'Polygon',
  sides: 'Sides',
  rectangleTool: 'Rectangle',
  cornerRadius: 'Corner Radius',
  resizeWidthTitle: 'Width in pixels',
  resizeHeightTitle: 'Height in pixels',
  toggleRatioLockTitle: 'Toggle ratio lock',
  reset: 'Reset',
  resetSize: 'Reset to original image size',
  rotateTool: 'Rotate',
  textTool: 'Text',
  textSpacings: 'Text spacings',
  textAlignment: 'Text alignment',
  fontFamily: 'Font family',
  size: 'Size',
  letterSpacing: 'Letter Spacing',
  lineHeight: 'Line height',
  warmthTool: 'Warmth',
  addWatermark: '+ Add watermark',
  addWatermarkTitle: 'Choose the watermark type',
  uploadWatermark: 'Upload watermark',
  addWatermarkAsText: 'Add as text',
  padding: 'Padding',
  shadow: 'Shadow',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  blur: 'Blur',
  opacity: 'Opacity',
  position: 'Position',
  stroke: 'Stroke',
  saveAsModalLabel: 'Save the image as',
  extension: 'Extension',
  nameIsRequired: 'Name is required.',
  quality: 'Quality',
  imageDimensionsHoverTitle: 'Saved image size (width x height)',
  cropSizeLowerThanResizedWarning:
    'Note, the selected crop area is lower than the applied resize which might cause quality decrease',
  actualSize: 'Actual size (100%)',
  fitSize: 'Fit size',
  addImageTitle: 'Select image to add...',
};

const Zh = {
  name: '名称',
  save: '保存',
  saveAs: '另存为',
  back: '返回',
  loading: '加载中...',
  resetOperations: '重置/删除所有操作',
  changesLoseConfirmation: '所有更改将被丢失',
  changesLoseConfirmationHint: '您确定要继续吗？',
  cancel: '取消',
  continue: '继续',
  undoTitle: '撤销上一个操作',
  redoTitle: '重做上一个操作',
  showImageTitle: '显示原始图像',
  zoomInTitle: '放大',
  zoomOutTitle: '缩小',
  toggleZoomMenuTitle: '切换缩放菜单',
  adjustTab: '调整',
  finetuneTab: '微调',
  filtersTab: '滤镜',
  watermarkTab: '水印',
  annotateTab: '绘制',
  resize: '调整大小',
  resizeTab: '调整大小',
  invalidImageError: '提供的图像无效。',
  uploadImageError: '上传图像时出错。',
  areNotImages: '不是图片',
  isNotImage: '不是图片',
  toBeUploaded: '要上传',
  cropTool: '裁剪',
  original: '原图',
  custom: '自定义',
  square: '正方形',
  landscape: '横向',
  portrait: '纵向',
  ellipse: '椭圆',
  classicTv: '经典电视',
  cinemascope: '宽屏',
  arrowTool: '箭头',
  blurTool: '模糊',
  brightnessTool: '亮度',
  contrastTool: '对比度',
  ellipseTool: '椭圆形',
  unFlipX: '取消X轴翻转',
  flipX: 'X轴翻转',
  unFlipY: '取消Y轴翻转',
  flipY: 'Y轴翻转',
  hsvTool: '饱和度',
  hue: '色相',
  saturation: '饱和度',
  value: '亮度',
  imageTool: '图像',
  importing: '导入中...',
  addImage: '+ 添加图片',
  uploadImage: '上传图片',
  fromGallery: '从库中选择',
  lineTool: '直线',
  penTool: '画笔',
  polygonTool: '多边形',
  sides: '边数',
  rectangleTool: '矩形',
  cornerRadius: '圆角半径',
  resizeWidthTitle: '宽度（像素）',
  resizeHeightTitle: '高度（像素）',
  toggleRatioLockTitle: '切换比例锁定',
  reset: '重置',
  resetSize: '重置为原始图像大小',
  rotateTool: '旋转',
  textTool: '文本',
  textSpacings: '文本间距',
  textAlignment: '文本对齐方式',
  fontFamily: '字体',
  size: '字号',
  letterSpacing: '字间距',
  lineHeight: '行高',
  warmthTool: '温暖度',
  addWatermark: '+ 添加水印',
  addWatermarkTitle: '选择水印类型',
  uploadWatermark: '上传水印',
  addWatermarkAsText: '添加文本水印',
  padding: '内边距',
  shadow: '阴影',
  horizontal: '水平',
  vertical: '垂直',
  blur: '模糊',
  opacity: '透明度',
  position: '位置',
  stroke: '描边宽度',
  saveAsModalLabel: '将图像另存为',
  extension: '文件格式',
  nameIsRequired: '名称不能为空。',
  quality: '质量',
  imageDimensionsHoverTitle: '保存的图像尺寸（宽 x 高）',
  cropSizeLowerThanResizedWarning: '注意，所选的裁剪区域比调整的大小小，可能会导致质量下降',
  actualSize: '实际大小（100％）',
  fitSize: '适应大小',
  addImageTitle: '选择要添加的图像...',
}

export default Zh;